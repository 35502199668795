<template>
  <div class="details-top-car-pictures-side-carousel carousel-container small">
    <swiper-container
      class="details-top-car-pictures-side-carousel"
      :loop="photos.length > 3"
      :centered-slides="photos.length > 3"
      :navigation="photos.length > 3"
      :slides-per-view="photos.length < 3 ? photos.length : slidesPerView"
      :initial-slide="photos.length < 3 ? 0 : slidesPerView === 3 ? 1 : 0"
      :space-between="0"
    >
      <swiper-slide
        class="details-top-car-pictures-side-picture-container"
        v-for="photo in photos"
        :key="photo"
      >
        <img
          class="details-top-car-pictures-side-picture"
          :src="photo"
          alt=""
          loading="lazy"
        />
      </swiper-slide>
    </swiper-container>
  </div>
</template>


<script>
  export default {
    name: "vehicle-images",
    data: () => ( {
      windowWidth: window.innerWidth,
    } ),
    props: {
      photos : {
        type: Array,
        required: true
      },
      carId : {
        type: String,
        required: true
      },
    },
    mounted() {
      window.addEventListener("resize", this.handleResize);
    },
    computed: {
      slidesPerView() {
        if ( this.windowWidth <= 768 ) return 1;
        else return 3;
      },
    },
    methods: {
      handleResize() {
        this.windowWidth = window.innerWidth;
      },
    },
  }
</script>
