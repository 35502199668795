var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "details-top-car-pictures-side-carousel carousel-container small",
    },
    [
      _c(
        "swiper-container",
        {
          staticClass: "details-top-car-pictures-side-carousel",
          attrs: {
            loop: _vm.photos.length > 3,
            "centered-slides": _vm.photos.length > 3,
            navigation: _vm.photos.length > 3,
            "slides-per-view":
              _vm.photos.length < 3 ? _vm.photos.length : _vm.slidesPerView,
            "initial-slide":
              _vm.photos.length < 3 ? 0 : _vm.slidesPerView === 3 ? 1 : 0,
            "space-between": 0,
          },
        },
        _vm._l(_vm.photos, function (photo) {
          return _c(
            "swiper-slide",
            {
              key: photo,
              staticClass: "details-top-car-pictures-side-picture-container",
            },
            [
              _c("img", {
                staticClass: "details-top-car-pictures-side-picture",
                attrs: { src: photo, alt: "", loading: "lazy" },
              }),
            ]
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }