var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "page-container vdp pt-5" },
    [
      _c(
        "div",
        { staticClass: "d-none d-md-block topButtons" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "2" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass:
                        "btn btn-back btn-icon d-flex justify-content-around align-items-center mr-2 mr-xl-3",
                      attrs: { onClick: "history.back()" },
                    },
                    [
                      _c("b-icon", { attrs: { icon: "chevron-left" } }),
                      _vm._v(" "),
                      _c("span", [_vm._v(" Back to Results")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                {
                  staticClass: "d-flex justify-content-end align-items-center",
                  attrs: { cols: "5", offset: "5" },
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass:
                        "btn btn-right btn-icon d-flex justify-content-around align-items-center mr-2 mr-xl-3",
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "check-circle" },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "d-none d-md-inline" }, [
                        _vm._v(" Confirm"),
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v(" Availability")]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass:
                        "btn btn-right btn-icon d-flex justify-content-around align-items-center mr-2 mr-xl-3",
                      attrs: { onclick: "window.openAutoHubWidget()" },
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "bar-chart-line" },
                      }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "header-bottom-popup-value-trade-text" },
                        [
                          _c("span", { staticClass: "d-none d-md-inline" }, [
                            _vm._v(" Value Your"),
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v(" Trade")]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-button",
                    {
                      staticClass:
                        "btn btn-right btn-icon d-flex justify-content-around align-items-center mr-2 mr-xl-3",
                      on: { click: _vm.copyLink },
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "share-fill" },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v(" Share")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "d-none d-md-inline" }, [
                        _vm._v(" This Car"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-modal",
                    {
                      attrs: {
                        "hide-footer": "",
                        id: "vdp-share-modal",
                        title: "Share this search",
                      },
                    },
                    [
                      _c("div", { staticClass: "d-block text-center" }, [
                        _c("p", { staticClass: "my-4" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\tCopy to share this link to your platform of choice!\n\t\t\t\t\t\t\t"
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "p",
                          {
                            staticClass: "my-4",
                            staticStyle: { "font-size": "12px" },
                          },
                          [_c("b", [_vm._v(_vm._s(_vm.shareLink))])]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-button-group",
                        { staticClass: "w-100" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-3",
                              attrs: { variant: "outline-primary", block: "" },
                              on: { click: _vm.copyLink },
                            },
                            [
                              _c("b-icon", {
                                attrs: { icon: "files", "flip-h": "" },
                              }),
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\tCopy Link\n\t\t\t\t\t\t\t"
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-3",
                              attrs: { variant: "outline-danger", block: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.$bvModal.hide("vdp-share-modal")
                                },
                              },
                            },
                            [_vm._v("\n\t\t\t\t\t\t\t\tClose\n\t\t\t\t\t\t\t")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "vdpSlider pt-4" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c("vehicle-images", {
                    attrs: {
                      photos: _vm.carData.sent.photos,
                      "car-id": _vm.carData.sent.stock_number,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-none d-md-block", attrs: { id: "desktopVersion" } },
        [
          _c(
            "b-row",
            {
              staticClass: "pt-5 mx-auto",
              staticStyle: { "max-width": "1440px" },
            },
            [
              _c(
                "b-col",
                { attrs: { cols: "8" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "vdp-title-row" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _vm.carData.pulled &&
                          Object.keys(_vm.carData.pulled).length !== 0 &&
                          _vm.carData.pulled.constructor === Object
                            ? _c(
                                "div",
                                { staticClass: "vdp-title-row-title pb-2" },
                                [
                                  _vm.carData.sent.new
                                    ? _c("span", [_vm._v("New")])
                                    : _vm._e(),
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t" +
                                      _vm._s(_vm.carData.pulled.car.title) +
                                      "\n\t\t\t\t\t\t\t"
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            { staticClass: "justify-content-start" },
                            [
                              _c("b-col", { attrs: { cols: "4" } }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "vdp-title-row-vin details-vin",
                                  },
                                  [
                                    _vm._v("\n\t\t\t\t\t\t\t\t\t\tVin: "),
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bolder" },
                                      [_vm._v(_vm._s(_vm.carData.sent.vin))]
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("b-col", { attrs: { cols: "4" } }, [
                                _c(
                                  "p",
                                  {
                                    staticClass:
                                      "vdp-title-row-stock details-stock m-0",
                                  },
                                  [
                                    _vm._v("\n\t\t\t\t\t\t\t\t\t\tStock #: "),
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bolder" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.carData.sent.stock_number)
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    { staticClass: "vdp-specsRow-row" },
                    [
                      _c(
                        "b-col",
                        {
                          staticStyle: { "min-height": "150px" },
                          attrs: { cols: "12" },
                        },
                        [
                          _c(
                            "b-row",
                            { staticClass: "vdp-specsRow-row-title" },
                            [
                              _c("b-col", { attrs: { cols: "12" } }, [
                                _c(
                                  "h3",
                                  {
                                    staticClass: "vdp-specsRow-row-title-text",
                                  },
                                  [_vm._v("Specifications")]
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            _vm._l(_vm.keySpecs, function (value, key) {
                              return _c(
                                "b-col",
                                { key: key, attrs: { cols: "3" } },
                                [
                                  Array.isArray(value)
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "vdp-specsRow-row-item",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vdp-specsRow-row-item-title",
                                            },
                                            [_vm._v(_vm._s(key))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vdp-specsRow-row-item-value",
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "vdp-specsRow-row-item-value-text",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        value[0]
                                                          ? value[0]
                                                              .toString(10)
                                                              .replace(
                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                ","
                                                              )
                                                          : "--"
                                                      ) +
                                                      " City* / " +
                                                      _vm._s(
                                                        value[1]
                                                          ? value[1]
                                                              .toString(10)
                                                              .replace(
                                                                /\B(?=(\d{3})+(?!\d))/g,
                                                                ","
                                                              )
                                                          : "--"
                                                      ) +
                                                      " Highway*\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : key.includes("Color")
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "vdp-specsRow-row-item",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vdp-specsRow-row-item-title",
                                            },
                                            [_vm._v(_vm._s(key))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vdp-specsRow-row-item-value d-flex justify-content-start align-items-center",
                                            },
                                            [
                                              _c("span", {
                                                staticClass: "colorDot mr-1",
                                                style: {
                                                  "background-color":
                                                    _vm.transformColor(value),
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "vdp-specsRow-row-item-value-text mb-0",
                                                },
                                                [_vm._v(_vm._s(value))]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass: "vdp-specsRow-row-item",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vdp-specsRow-row-item-title",
                                            },
                                            [_vm._v(_vm._s(key))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "vdp-specsRow-row-item-value",
                                            },
                                            [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "vdp-specsRow-row-item-value-text",
                                                },
                                                [_vm._v(_vm._s(value))]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                ]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    { staticClass: "vdp-badge-sash-row" },
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "d-flex align-items-center",
                          attrs: { cols: "6" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "iPacketSection d-flex align-content-center justify-contnet-center badge",
                            },
                            [
                              _c("div", { staticClass: "usedIPacketbutton" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: `https://www.ipacket.info/${_vm.carData.sent.vin}`,
                                      target: "_blank",
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: `https://webicon.autoipacket.com/info?id=27D4CFC70F5DDFD2A9&vin=${_vm.carData.sent.vin}`,
                                        alt: "",
                                        loading: "lazy",
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.vehicle.certification
                        ? _c(
                            "b-col",
                            { staticClass: "badge", attrs: { cols: "3" } },
                            [
                              _c("img", {
                                staticClass: "cpoBadge",
                                attrs: {
                                  loading: "lazy",
                                  src: _vm.cPOBadgeMap[
                                    _vm.vehicle.certification
                                  ].src,
                                  alt: _vm.cPOBadgeMap[
                                    _vm.vehicle.certification
                                  ].alt,
                                },
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-col",
                        {
                          staticClass: "dealRatingBadge badge",
                          attrs: { cols: "3" },
                        },
                        [
                          _c("span", {
                            attrs: {
                              "data-cg-vin": _vm.vehicle.vin,
                              "data-cg-price": _vm.vehicle.price,
                            },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    [
                      _c("hr"),
                      _vm._v(" "),
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c("div", {
                          staticStyle: { width: "100%", height: "30%" },
                          attrs: {
                            id: "custom-player-integration",
                            "data-vin": _vm.carData.sent.vin,
                          },
                        }),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "b-card",
                    { staticClass: "vdp-side-card" },
                    [
                      _vm.carData.pulled &&
                      Object.keys(_vm.carData.pulled).length !== 0 &&
                      _vm.carData.pulled.constructor === Object
                        ? _c(
                            "b-row",
                            { staticClass: "vdp-side-card-price-row" },
                            [
                              _c("b-col", { attrs: { cols: "12" } }, [
                                _c("div", [
                                  _vm.carData.pulled.car.other_price &&
                                  parseInt(
                                    _vm.carData.pulled.car.other_price,
                                    10
                                  ) -
                                    parseInt(_vm.carData.pulled.car.price, 10) >
                                    0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vdp-side-card-price-row-msrp-deal",
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "vdp-side-card-price-row-msrp-number-ridge",
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t$" +
                                                  _vm._s(
                                                    _vm._f("formatPrices")(
                                                      parseInt(
                                                        _vm.carData.pulled.car
                                                          .other_price
                                                      )
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "ml-5",
                                                  staticStyle: {
                                                    "font-size": "14px",
                                                    "font-weight": "normal",
                                                  },
                                                },
                                                [_vm._v("WAS")]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "vdp-side-card-price-row-msrp-number-ridge",
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t-$" +
                                                  _vm._s(
                                                    _vm._f("formatPrices")(
                                                      parseInt(
                                                        _vm.carData.pulled.car
                                                          .other_price,
                                                        10
                                                      ) -
                                                        parseInt(
                                                          _vm.carData.pulled.car
                                                            .price,
                                                          10
                                                        )
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "ml-5",
                                                  staticStyle: {
                                                    "font-size": "14px",
                                                    "font-weight": "normal",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\tDealer Discount\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "vdp-side-card-price-row-msrp-number-ridge-bold",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "mr-5",
                                                  staticStyle: {
                                                    "font-weight": "normal",
                                                  },
                                                },
                                                [_vm._v("Now")]
                                              ),
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t$" +
                                                  _vm._s(
                                                    _vm._f("formatPrices")(
                                                      _vm.carData.pulled.car
                                                        .price
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm.carData.pulled.car.price
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vdp-side-card-price-row-msrp",
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "vdp-side-card-price-row-msrp-label",
                                            },
                                            [_vm._v("Price")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "vdp-side-card-price-row-msrp-number",
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t$" +
                                                  _vm._s(
                                                    _vm._f("formatPrices")(
                                                      _vm.carData.pulled.car
                                                        .price
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vdp-side-card-price-row-msrp",
                                        },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "vdp-side-card-price-row-msrp-non-number",
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "vdp-side-card-price-row-callForPrice btn btn-tertiary btn-block btn-sm",
                                                  attrs: {
                                                    href: "tel:1-559-203-3607",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "mr-3" },
                                                    [
                                                      _c("b-icon", {
                                                        staticStyle: {
                                                          color: "#0072CE",
                                                        },
                                                        attrs: {
                                                          icon: "telephone",
                                                          "flip-h": "",
                                                          "font-scale": "1.33",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        color: "#0072CE",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\tCall For More Details\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                ]),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.carData.pulled &&
                      Object.keys(_vm.carData.pulled).length !== 0 &&
                      _vm.carData.pulled.constructor === Object
                        ? _c(
                            "b-row",
                            { staticClass: "vdp-button-holster-row" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "vdp-button-holster-row-call-button mb-2",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "btn-moreDetails d-flex align-items-center justify-content-center",
                                      attrs: { href: "tel:1-559-203-3607" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "mr-3" },
                                        [
                                          _c("b-icon", {
                                            staticStyle: { color: "#0072CE" },
                                            attrs: {
                                              icon: "telephone",
                                              "flip-h": "",
                                              "font-scale": "1.33",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticStyle: { color: "#0072CE" } },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\tCall for More Details\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "vdp-button-holster-row-testDrive-button",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c("span", {
                                    staticClass:
                                      "drivemotors modalup-placement",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "data-ext-dealer-code": "2264",
                                      "data-vin": _vm.carData.pulled.car.vin,
                                      "data-msrp": _vm.carData.pulled.car.msrp,
                                      "data-price":
                                        _vm.carData.pulled.car.price,
                                      "data-stock-code":
                                        _vm.carData.pulled.car.stock_number,
                                      "data-model-num":
                                        _vm.carData.pulled.car.model,
                                      "data-model-trim":
                                        _vm.carData.pulled.car.trim,
                                      "data-ext-color":
                                        _vm.carData.pulled.car.colour,
                                      "data-int-color":
                                        _vm.carData.pulled.car.interior_color,
                                      "data-is-new":
                                        _vm.carData.pulled.car.new === "true"
                                          ? 1
                                          : 0,
                                      "data-miles": _vm.carData.pulled.car
                                        .odometer
                                        ? _vm.carData.pulled.car.odometer
                                        : 0,
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "btn-testDrive btn btn-primary d-flex align-items-center justify-content-center",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "mr-1" },
                                        [
                                          _c("b-icon", {
                                            attrs: {
                                              icon: "key-fill",
                                              "font-scale": "1.33",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v("Schedule A Test Drive"),
                                      ]),
                                    ]
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                {
                                  staticClass: "mb-2 p-0",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c("div", {
                                    staticClass: "cn-button-container",
                                    attrs: {
                                      "data-vin": _vm.carData.pulled.car.vin,
                                      "data-page-type": "vdp",
                                      "data-type": "used",
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "vdp-button-holser-row-capitalOne-button p-0",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _vm.carData.pulled.car.price &&
                                  _vm.carData.pulled.car.year > 2007 &&
                                  _vm.carData.pulled.car.odometer < 150000
                                    ? _c(
                                        "div",
                                        { staticClass: "capital-one-wrapper" },
                                        [
                                          _c("div", {
                                            staticClass:
                                              "capital-one-stacked-button pb-0 vdp-button-holser-row-capitalOne-button",
                                            attrs: {
                                              "data-client-token":
                                                "ee8c2582-7330-4364-84df-9d01e0219e1b",
                                              "data-sales-price":
                                                _vm.carData.pulled.car.price,
                                              "data-vehicle-image-url":
                                                _vm.carData.pulled.car
                                                  .thumbnail,
                                              "data-vin":
                                                _vm.carData.pulled.car.vin,
                                            },
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-content-center justify-content-center mb-3",
                                },
                                [
                                  _c("p", { staticClass: "disclaimerText" }, [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t\tStock images are for illustration purposes only\n\t\t\t\t\t\t\t\t"
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            {
              staticClass: "mt-3 mx-auto",
              staticStyle: { "max-width": "1440px" },
            },
            [
              _c("b-col", { attrs: { cols: "12" } }, [
                _vm.carData.pulled &&
                Object.keys(_vm.carData.pulled).length !== 0 &&
                _vm.carData.pulled.constructor === Object
                  ? _c(
                      "div",
                      { staticClass: "vdp-more-specs-collapse" },
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle.moreSpecs",
                                modifiers: { moreSpecs: true },
                              },
                            ],
                            staticClass: "vdp-more-specs-collapse-button",
                            attrs: { block: "" },
                          },
                          [
                            _vm._v(
                              "\n\t\t\t\t\t\t\tAll Features\n\t\t\t\t\t\t\t"
                            ),
                            _c("b-icon", {
                              staticClass: "ml-3",
                              attrs: { icon: "chevron-down" },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-collapse",
                          { attrs: { id: "moreSpecs" } },
                          [
                            _c(
                              "b-card",
                              {
                                staticStyle: { height: "unset" },
                                attrs: { title: "Features" },
                              },
                              _vm._l(
                                _vm.carData.pulled.car.featureColumns,
                                function (feature, i) {
                                  return _c(
                                    "b-row",
                                    { key: i + "_" + feature },
                                    _vm._l(
                                      _vm.carData.pulled.car.features
                                        .split("|")
                                        .slice(i * 2, i * 2 + 2),
                                      function (item, i) {
                                        return _c(
                                          "b-col",
                                          {
                                            key: i + "_" + item,
                                            attrs: { cols: "6" },
                                          },
                                          [
                                            _c("li", [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(item) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                    1
                                  )
                                }
                              ),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-md-none", attrs: { id: "mobileVersion" } },
        [
          _c(
            "b-row",
            { staticClass: "mobile-vdp-title-row" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass:
                        "btn btn-back btn-icon d-flex justify-content-around align-items-center mr-2 mr-xl-3",
                      attrs: { onClick: "history.back()" },
                    },
                    [
                      _c("b-icon", { attrs: { icon: "chevron-left" } }),
                      _vm._v(" "),
                      _c("span", [_vm._v(" Back to Results")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.carData.pulled &&
              Object.keys(_vm.carData.pulled).length !== 0 &&
              _vm.carData.pulled.constructor === Object
                ? _c("b-col", { attrs: { cols: "12" } }, [
                    _c("div", { staticClass: "vdp-title-row-title pb-2" }, [
                      _vm._v(
                        "\n\t\t\t\t\t\t" +
                          _vm._s(_vm.carData.pulled.car.title) +
                          "\n\t\t\t\t\t"
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("b-col", { attrs: { cols: "6" } }, [
                _c("p", { staticClass: "vdp-title-row-vin details-vin" }, [
                  _vm._v("\n\t\t\t\t\t\tVin: "),
                  _c("span", { staticClass: "font-weight-bolder" }, [
                    _vm._v(_vm._s(_vm.carData.sent.vin)),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("b-col", { attrs: { cols: "6" } }, [
                _c(
                  "p",
                  {
                    staticClass: "vdp-title-row-stock details-stock m-0",
                    staticStyle: { "text-align": "right" },
                  },
                  [
                    _vm._v("\n\t\t\t\t\t\tStock #: "),
                    _c("span", { staticClass: "font-weight-bolder" }, [
                      _vm._v(_vm._s(_vm.carData.sent.stock_number)),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "mobile-button-row" },
            [
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass:
                        "btn btn-tertiary btn-icon d-flex justify-content-center align-items-center mr-2 mr-xl-3",
                      staticStyle: {
                        background:
                          "linear-gradient(180deg, #017FF2 0%, #004584 100%), #0068C7 !important",
                        "box-shadow": "0px 4px 24px !important",
                        "border-radius": "5px !important",
                        "font-weight": "600 !important",
                        height: "44px",
                        width: "100% !important",
                      },
                      attrs: { href: "#" },
                    },
                    [
                      _c("b-icon", {
                        staticClass: "mr-1",
                        attrs: { icon: "check-circle" },
                      }),
                      _vm._v(" "),
                      _c("span", { staticClass: "d-none d-md-inline" }, [
                        _vm._v(" Confirm"),
                      ]),
                      _vm._v(" "),
                      _c("span", [_vm._v(" Availability")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "b-button",
                    {
                      staticClass:
                        "btn flex justify-content-center align-items-center mr-2 mr-xl-3",
                      staticStyle: {
                        background:
                          "linear-gradient(180deg, #017FF2 0%, #004584 100%), #0068C7",
                        "box-shadow": "0px 4px 24px",
                        "border-radius": "5px !important",
                        "font-weight": "600",
                        height: "44px",
                        width: "100% !important",
                      },
                      attrs: { onclick: "window.openAutoHubWidget()" },
                    },
                    [
                      _c("b-icon", { attrs: { icon: "bar-chart-line" } }),
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "header-bottom-popup-value-trade-text" },
                        [
                          _c("span", { staticClass: "d-none d-md-inline" }, [
                            _vm._v(" Value Your"),
                          ]),
                          _vm._v(" "),
                          _c("span", [_vm._v(" Trade")]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-col",
                { attrs: { cols: "4" } },
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.vdp-share-modal",
                          modifiers: { "vdp-share-modal": true },
                        },
                      ],
                      staticClass:
                        "btn flex justify-content-center align-items-center mr-2 mr-xl-3",
                      staticStyle: {
                        background:
                          "linear-gradient(180deg, #017FF2 0%, #004584 100%), #0068C7",
                        "box-shadow": "0px 4px 24px",
                        "border-radius": "5px !important",
                        "font-weight": "600",
                        height: "44px",
                        width: "100% !important",
                      },
                    },
                    [
                      _c("b-icon", { attrs: { icon: "share-fill" } }),
                      _vm._v(" "),
                      _c("span", [_vm._v(" Share")]),
                      _vm._v(" "),
                      _c("span", { staticClass: "d-none d-md-inline" }, [
                        _vm._v(" This Car"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "mobile-vdp-specsRow-row" },
            [
              _c(
                "b-col",
                {
                  staticStyle: { "min-height": "220px" },
                  attrs: { cols: "12" },
                },
                [
                  _c(
                    "b-row",
                    { staticClass: "vdp-specsRow-row-title" },
                    [
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c(
                          "h3",
                          { staticClass: "vdp-specsRow-row-title-text" },
                          [_vm._v("Specifications")]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-row",
                    _vm._l(_vm.keySpecs, function (value, key) {
                      return _c("b-col", { key: key, attrs: { cols: "6" } }, [
                        Array.isArray(value)
                          ? _c(
                              "div",
                              { staticClass: "vdp-specsRow-row-item" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vdp-specsRow-row-item-title",
                                  },
                                  [_vm._v(_vm._s(key))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vdp-specsRow-row-item-value",
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "vdp-specsRow-row-item-value-text",
                                      },
                                      [
                                        _vm._v(
                                          "\n\t\t\t\t\t\t\t\t\t\t" +
                                            _vm._s(
                                              value[0]
                                                ? value[0]
                                                    .toString(10)
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )
                                                : "--"
                                            ) +
                                            " City* / " +
                                            _vm._s(
                                              value[1]
                                                ? value[1]
                                                    .toString(10)
                                                    .replace(
                                                      /\B(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )
                                                : "--"
                                            ) +
                                            " Highway*\n\t\t\t\t\t\t\t\t\t"
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : key.includes("Color")
                          ? _c(
                              "div",
                              { staticClass: "vdp-specsRow-row-item" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vdp-specsRow-row-item-title",
                                  },
                                  [_vm._v(_vm._s(key))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "vdp-specsRow-row-item-value d-flex justify-content-start align-items-center",
                                  },
                                  [
                                    _c("span", {
                                      staticClass: "colorDot mr-1",
                                      style: {
                                        "background-color":
                                          _vm.transformColor(value),
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "vdp-specsRow-row-item-value-text mb-0",
                                      },
                                      [_vm._v(_vm._s(value))]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _c(
                              "div",
                              { staticClass: "vdp-specsRow-row-item" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vdp-specsRow-row-item-title",
                                  },
                                  [_vm._v(_vm._s(key))]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vdp-specsRow-row-item-value",
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass:
                                          "vdp-specsRow-row-item-value-text",
                                      },
                                      [_vm._v(_vm._s(value))]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                      ])
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c(
            "b-row",
            { staticClass: "vdp-badge-sash-row" },
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex justify-content-center",
                  attrs: { cols: "12" },
                },
                [
                  _c("div", { staticClass: "iPacketSection badge" }, [
                    _c("div", { staticClass: "newIPacketbutton" }, [
                      _c(
                        "a",
                        {
                          attrs: {
                            href: `https://www.ipacket.info/${_vm.carData.sent.vin}`,
                            target: "_blank",
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              src: `https://webicon.autoipacket.com/info?id=27D4CFC70F5DDFD2AE&vin=${_vm.carData.sent.vin}`,
                              alt: "",
                              loading: "lazy",
                            },
                          }),
                        ]
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.vehicle.certification
                ? _c("b-col", { staticClass: "badge", attrs: { cols: "6" } }, [
                    _c("img", {
                      staticClass: "cpoBadge",
                      attrs: {
                        loading: "lazy",
                        src: _vm.cPOBadgeMap[_vm.vehicle.certification].src,
                        alt: _vm.cPOBadgeMap[_vm.vehicle.certification].alt,
                      },
                    }),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "b-col",
                { staticClass: "dealRatingBadge badge", attrs: { cols: "6" } },
                [
                  _c("span", {
                    attrs: {
                      "data-cg-vin": _vm.vehicle.vin,
                      "data-cg-price": _vm.vehicle.price,
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-card",
                {
                  staticClass: "p-4",
                  staticStyle: { height: "unset !important" },
                },
                [
                  _vm.carData.pulled &&
                  Object.keys(_vm.carData.pulled).length !== 0 &&
                  _vm.carData.pulled.constructor === Object
                    ? _c(
                        "b-row",
                        { staticClass: "vdp-side-card-price-row" },
                        [
                          _c("b-col", { attrs: { cols: "12" } }, [
                            _c("div", [
                              _vm.carData.pulled.car.other_price &&
                              parseInt(_vm.carData.pulled.car.other_price, 10) -
                                parseInt(_vm.carData.pulled.car.price, 10) >
                                0
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vdp-side-card-price-row-msrp-deal",
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "vdp-side-card-price-row-msrp-number-ridge",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t$" +
                                              _vm._s(
                                                _vm._f("formatPrices")(
                                                  parseInt(
                                                    _vm.carData.pulled.car
                                                      .other_price
                                                  )
                                                )
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "ml-5",
                                              staticStyle: {
                                                "font-size": "14px",
                                                "font-weight": "normal",
                                              },
                                            },
                                            [_vm._v("WAS")]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "vdp-side-card-price-row-msrp-number-ridge",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t-$" +
                                              _vm._s(
                                                _vm._f("formatPrices")(
                                                  parseInt(
                                                    _vm.carData.pulled.car
                                                      .other_price,
                                                    10
                                                  ) -
                                                    parseInt(
                                                      _vm.carData.pulled.car
                                                        .price,
                                                      10
                                                    )
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "ml-5",
                                              staticStyle: {
                                                "font-size": "14px",
                                                "font-weight": "normal",
                                              },
                                            },
                                            [_vm._v("Dealer Discount")]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "vdp-side-card-price-row-msrp-number-ridge-bold",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "mr-5",
                                              staticStyle: {
                                                "font-weight": "normal",
                                              },
                                            },
                                            [_vm._v("Now")]
                                          ),
                                          _vm._v(
                                            "$" +
                                              _vm._s(
                                                _vm._f("formatPrices")(
                                                  _vm.carData.pulled.car.price
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm.carData.pulled.car.price
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vdp-side-card-price-row-msrp",
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "vdp-side-card-price-row-msrp-label",
                                        },
                                        [_vm._v("Price")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "vdp-side-card-price-row-msrp-number",
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t$" +
                                              _vm._s(
                                                _vm._f("formatPrices")(
                                                  _vm.carData.pulled.car.price
                                                )
                                              ) +
                                              "\n\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      staticClass:
                                        "vdp-side-card-price-row-msrp",
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "vdp-side-card-price-row-msrp-non-number",
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "vdp-side-card-price-row-callForPrice btn btn-tertiary btn-block btn-sm",
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\tCall For More Details\n\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.carData.pulled &&
                  Object.keys(_vm.carData.pulled).length !== 0 &&
                  _vm.carData.pulled.constructor === Object
                    ? _c(
                        "b-row",
                        { staticClass: "vdp-button-holster-row" },
                        [
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "vdp-button-holster-row-call-button mb-2",
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "btn-moreDetails d-flex align-items-center justify-content-center",
                                  attrs: { href: "tel:1-559-203-3607" },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "mr-3" },
                                    [
                                      _c("b-icon", {
                                        staticStyle: { color: "#0072CE" },
                                        attrs: {
                                          icon: "telephone",
                                          "flip-h": "",
                                          "font-scale": "1.33",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "#0072CE" } },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t\tCall for More Details\n\t\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "vdp-button-holster-row-testDrive-button mb-2",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("span", {
                                staticClass: "drivemotors modalup-placement",
                                staticStyle: { width: "100%" },
                                attrs: {
                                  "data-ext-dealer-code": "2264",
                                  "data-vin": _vm.carData.pulled.car.vin,
                                  "data-msrp": _vm.carData.pulled.car.msrp,
                                  "data-price": _vm.carData.pulled.car.price,
                                  "data-stock-code":
                                    _vm.carData.pulled.car.stock_number,
                                  "data-model-num":
                                    _vm.carData.pulled.car.model,
                                  "data-model-trim":
                                    _vm.carData.pulled.car.trim,
                                  "data-ext-color":
                                    _vm.carData.pulled.car.colour,
                                  "data-int-color":
                                    _vm.carData.pulled.car.interior_color,
                                  "data-is-new":
                                    _vm.carData.pulled.car.new === "true"
                                      ? 1
                                      : 0,
                                  "data-miles": _vm.carData.pulled.car.odometer
                                    ? _vm.carData.pulled.car.odometer
                                    : 0,
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "btn-testDrive btn btn-primary d-flex align-items-center justify-content-center",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "mr-1" },
                                    [
                                      _c("b-icon", {
                                        attrs: {
                                          icon: "key-fill",
                                          "font-scale": "1.33",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("Schedule A Test Drive")]),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { staticClass: "mb-3 p-0", attrs: { cols: "12" } },
                            [
                              _c("div", {
                                staticClass: "cn-button-container",
                                attrs: {
                                  "data-vin": _vm.carData.pulled.car.vin,
                                  "data-page-type": "vdp",
                                  "data-type": "used",
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            {
                              staticClass:
                                "vdp-button-holser-row-capitalOne-button p-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _vm.carData.pulled.car.price &&
                              _vm.carData.pulled.car.year > 2007 &&
                              _vm.carData.pulled.car.odometer < 150000
                                ? _c(
                                    "div",
                                    { staticClass: "capital-one-wrapper" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "capital-one-prequalification-button pb-0 vdp-button-holser-row-capitalOne-button",
                                          attrs: {
                                            "data-client-token":
                                              "ee8c2582-7330-4364-84df-9d01e0219e1b",
                                            "data-sales-price":
                                              _vm.carData.pulled.car.price,
                                            "data-vehicle-image-url":
                                              _vm.carData.pulled.car.thumbnail,
                                            "data-vin":
                                              _vm.carData.pulled.car.vin,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\tExplore Financing\n\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c("hr"),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex align-content-center justify-content-center mb-3",
                            },
                            [
                              _c("p", { staticClass: "disclaimerText" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t\t\t\tStock images are for illustration purposes only\n\t\t\t\t\t\t\t"
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("hr"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "mx-auto", staticStyle: { "max-width": "1440px" } },
        [
          _c("div", {
            staticClass: "iPacket-module-section",
            attrs: { id: "aipModules" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "mx-auto mb-7 cpoSpecials-row" },
        [
          _c("b-col", { attrs: { cols: "12", lg: "6" } }, [
            _c("div", { staticClass: "panel cpoSpecials-section" }, [
              _c("div", { staticClass: "panel-header full-center" }, [
                _c("h2", [
                  _vm._v("Certified Pre-Owned Honda Specials in Fresno CA"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "panel-body" }, [
                _c("div", { staticClass: "panel-section warranty padding" }, [
                  _c("p", [
                    _vm._v(
                      "\n\t\t\t\t\t\t\t\tThink you need to drive all over Fresno to find your perfect\n\t\t\t\t\t\t\t\tused Honda? Think again! You can find the best pre-owned deals\n\t\t\t\t\t\t\t\ton our Certified Pre-Owned Specials page! Shop our Certified\n\t\t\t\t\t\t\t\tPre-Owned Honda specials to save big on the reliable vehicle\n\t\t\t\t\t\t\t\tyou’d like to take home. We have Certified Pre-Owned Honda SUV\n\t\t\t\t\t\t\t\tand Sedan offers you are sure to love at Clawson Honda of\n\t\t\t\t\t\t\t\tFresno.\n\t\t\t\t\t\t\t"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n\t\t\t\t\t\t\t\tOur finance specialists can help you drive home in a stylish\n\t\t\t\t\t\t\t\tvehicle at a great price, regardless of your credit. Our\n\t\t\t\t\t\t\t\tCertified Pre-Owned Honda Dealership in Fresno, CA, has a huge\n\t\t\t\t\t\t\t\tselection of used cars, trucks and SUVs for sale. Come\n\t\t\t\t\t\t\t\ttest-drive one of our Certified Pre-Owned specials today!\n\t\t\t\t\t\t\t"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-center mt-5" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { href: "/certified-pre-owned-specials/" },
                      },
                      [_vm._v("Certified Pre-Owned Specials")]
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _vm.carData.pulled &&
            Object.keys(_vm.carData.pulled).length !== 0 &&
            _vm.carData.pulled.constructor === Object
              ? _c("div", { staticClass: "panel recallLookup-section" }, [
                  _c("div", { staticClass: "panel-body" }, [
                    _c(
                      "div",
                      { staticClass: "panel-section warranty padding" },
                      [
                        _c("h2", { staticClass: "capitalize text-center" }, [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t\tCheck for unrepaired safety recalls\n\t\t\t\t\t\t\t"
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "text-center" }, [
                          _vm.carData.pulled.car.vin
                            ? _c(
                                "a",
                                {
                                  staticClass: "btn btn-primary",
                                  attrs: {
                                    href: `https://www.nhtsa.gov/recalls?vin=${_vm.carData.pulled.car.vin}`,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t\t\t\t\t\tNHTSA Recall Look-up\n\t\t\t\t\t\t\t\t"
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.recallModal",
                                  modifiers: { recallModal: true },
                                },
                              ],
                              staticClass: "clickable capitalize d-block mt-3",
                            },
                            [
                              _c("b-icon", {
                                attrs: { icon: "question-circle" },
                              }),
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t\tLearn about safety recalls\n\t\t\t\t\t\t\t\t"
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c(
            "b-modal",
            {
              attrs: { id: "recallModal", busy: "", "hide-footer": "" },
              scopedSlots: _vm._u([
                {
                  key: "modal-title",
                  fn: function () {
                    return [
                      _c(
                        "h5",
                        [
                          _c("b-icon", {
                            staticClass: "mr-2",
                            attrs: { icon: "info-circle" },
                          }),
                          _vm._v(
                            "\n\t\t\t\t\t\tLearn About Safety Recalls\n\t\t\t\t\t"
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("p", [
                  _vm._v(
                    "\n\t\t\t\t\t\tThis vehicle may have an open safety recall. A safety recall\n\t\t\t\t\t\toccurs when a manufacturer or the National Highway Traffic\n\t\t\t\t\t\tSafety Administration (NHTSA) finds that a condition or defect\n\t\t\t\t\t\tthat could affect the safety or operation of a vehicle may\n\t\t\t\t\t\texist in a specific vehicle or series of vehicles.\n\t\t\t\t\t"
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n\t\t\t\t\t\tClick on the NHTSA Recall Look-Up link to determine if this\n\t\t\t\t\t\tvehicle has an open safety recall.\n\t\t\t\t\t"
                  ),
                ]),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("b-col", { attrs: { cols: "12", lg: "6" } }, [
            _c("div", { staticClass: "panel vehicleUpgrade-section" }, [
              _c("div", { staticClass: "panel-header full-center" }, [
                _c("h2", [
                  _vm._v(
                    "Clawson Honda Vehicle Upgrade Program near Fresno, CA"
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "panel-body" }, [
                _c("div", { staticClass: "panel-section warranty padding" }, [
                  _c("p", [
                    _vm._v(
                      "\n\t\t\t\t\t\t\t\tUpgrade Today! Drive off in a newer, safer, more\n\t\t\t\t\t\t\t\ttechnologically advanced Honda for the same or lower monthly\n\t\t\t\t\t\t\t\tpayment. Why not treat yourself to a new ride? Arrive in style\n\t\t\t\t\t\t\t\twith an incredibly affordable and advanced vehicle from our\n\t\t\t\t\t\t\t\tHonda dealership near Selma, CA. Right now, we are offering an\n\t\t\t\t\t\t\t\tincredible chance for you to enhance your experience behind\n\t\t\t\t\t\t\t\tthe wheel with the Vehicle Upgrade Program. Come to Clawson\n\t\t\t\t\t\t\t\tHonda of Fresno today to take advantage of this outstanding\n\t\t\t\t\t\t\t\toffer!\n\t\t\t\t\t\t\t"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n\t\t\t\t\t\t\t\tMany of our new Honda models qualify for the Vehicle Upgrade\n\t\t\t\t\t\t\t\tProgram. If you are in the market for a new commuter car, we\n\t\t\t\t\t\t\t\thave new Honda Civic sedan and coupe models for sale, or you\n\t\t\t\t\t\t\t\tcan take a look at the new Honda Accord, too. For those who\n\t\t\t\t\t\t\t\tneed to fit the whole family, buy or lease a Honda CR-V or\n\t\t\t\t\t\t\t\tPilot. We even have the new Honda Ridgeline and Honda\n\t\t\t\t\t\t\t\tPassport, back in stock after a short hiatus. Whichever model\n\t\t\t\t\t\t\t\tyou have been eyeing, it may qualify for the Clawson Honda\n\t\t\t\t\t\t\t\tVehicle Upgrade Program! Peruse our new Honda inventory before\n\t\t\t\t\t\t\t\tyou visit our dealership, then come speak with our Honda\n\t\t\t\t\t\t\t\tfinance and sales teams to learn more about this program. They\n\t\t\t\t\t\t\t\twill be ready to help you with any questions you may have, and\n\t\t\t\t\t\t\t\twill be there with you throughout the process!\n\t\t\t\t\t\t\t"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h4", { staticClass: "capitalize" }, [
                    _vm._v(
                      "\n\t\t\t\t\t\t\t\tOther Benefits of Upgrading to a New Honda:\n\t\t\t\t\t\t\t"
                    ),
                  ]),
                  _vm._v(" "),
                  _c("ul", { staticClass: "bulletedList" }, [
                    _c("li", [
                      _vm._v("Get above market value for your trade-in."),
                    ]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Expedited sales process.")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("A newer vehicle.")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Lower monthly payments.")]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v(
                        "Lower fuel costs and increased miles per gallon."
                      ),
                    ]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Better financing terms.")]),
                    _vm._v(" "),
                    _c("li", [_vm._v("Reduced maintenance and repair costs.")]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v("Brand new comfort and entertainment features."),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _vm._v("Increased safety and security technology."),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "text-center mt-5" }, [
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-primary",
                        attrs: {
                          href: "https://clawsonhonda.com/vehicle-upgrade-program/",
                        },
                      },
                      [_vm._v("Vehicle Upgrade Program")]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm.carData.pulled &&
      Object.keys(_vm.carData.pulled).length !== 0 &&
      _vm.carData.pulled.constructor === Object
        ? _c(
            "b-row",
            {
              staticClass: "mx-auto mb-5",
              staticStyle: { "max-width": "1440px" },
            },
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _vm.carData.pulled.related_cars &&
                  _vm.carData.pulled.related_cars.length > 0
                    ? _c("b-card", { staticClass: "vdp-related" }, [
                        _c("h1", [_vm._v("Related Vehicles")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "vdp-related-cars" },
                          [
                            _c("related-vehicles", {
                              attrs: {
                                related_cars: _vm.carData.pulled.related_cars,
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "mx-auto mb-5", staticStyle: { "max-width": "1440px" } },
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _c("div", { staticClass: "disclaimer-section mb-4 px-7" }, [
              _c("p", [
                _vm._v(
                  "\n\t\t\t\t\t\tAll vehicles are one of each. All Pre-Owned or Certified vehicles are Used. Tax, Title, and Tags are not\n\t\t\t\t\t\tincluded in vehicle prices shown and must be paid by the purchaser. All offers expire on close of business\n\t\t\t\t\t\tthe\n\t\t\t\t\t\tday subject content is removed from website, and all financing is subject to credit approval. All\n\t\t\t\t\t\ttransactions\n\t\t\t\t\t\tare negotiable including price, trade allowance, interest rate (of which the dealer may retain a portion),\n\t\t\t\t\t\tterm,\n\t\t\t\t\t\tand documentary service fee. $85 dealer documentation charge is non-negotiable. Any agreement is subject to\n\t\t\t\t\t\texecution of contract documents. While great effort is made to ensure the accuracy of the information on\n\t\t\t\t\t\tthis\n\t\t\t\t\t\tsite, errors do occur so please verify information with a customer service rep. This is easily done by\n\t\t\t\t\t\tcalling\n\t\t\t\t\t\tus at 559-435-5000 or by visiting us at the dealership.\n\t\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n\t\t\t\t\t\tNew and used prices do not include government fees, and taxes, any finance charges, any dealer document\n\t\t\t\t\t\tprocessing charge, any electronic filing charge, and any emission testing charge. Notice, all of our\n\t\t\t\t\t\tvehicles\n\t\t\t\t\t\thave an anti-theft system installed. The advertised price excludes the purchase of this anti-theft system.\n\t\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n\t\t\t\t\t\t**With approved credit. Terms may vary. Monthly payments are only estimates derived from the vehicle price\n\t\t\t\t\t\twith\n\t\t\t\t\t\ta 72-month term, 4.9% interest, and 20% down payment.\n\t\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n\t\t\t\t\t\tSome of our used vehicles may be subject to unrepaired safety recalls. Check for a vehicle’s unrepaired\n\t\t\t\t\t\trecalls\n\t\t\t\t\t\tby VIN at "
                ),
                _c(
                  "a",
                  { attrs: { href: "https://vinrcl.safecar.gov/vin/" } },
                  [_vm._v("Check for Recalls Here")]
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n\t\t\t\t\t\t*MPG on non-hybrid vehicles based on EPA mileage ratings. Use for comparison purposes only. Your mileage\n\t\t\t\t\t\twill\n\t\t\t\t\t\tvary depending on how you drive and maintain your vehicle, driving conditions, and other factors.\n\t\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n\t\t\t\t\t\t*MPG for hybrid vehicles based on EPA mileage ratings. Use for comparison purposes only. Your mileage will\n\t\t\t\t\t\tvary\n\t\t\t\t\t\tdepending on how you drive and maintain your vehicle, driving conditions, battery-pack age/condition (hybrid\n\t\t\t\t\t\ttrims), and other factors.\n\t\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n\t\t\t\t\t\tWhile every effort has been made to ensure display of accurate data, the vehicle listings within this web\n\t\t\t\t\t\tsite\n\t\t\t\t\t\tmay not reflect all accurate vehicle items including photos, information appearing herein are presented and\n\t\t\t\t\t\tdeclared to the user on as “as-is” basis without any warranties of any kind, either expressed or implied,\n\t\t\t\t\t\tincluding but not limited to the implied warranties of merchantability. All inventory listed is subject to\n\t\t\t\t\t\tprior\n\t\t\t\t\t\tsale. The vehicle photo displayed may be an example only. Please see the dealer for details. Some vehicles\n\t\t\t\t\t\tshown\n\t\t\t\t\t\twith optional equipment. See the actual vehicle for complete accuracy of features, options & pricing.\n\t\t\t\t\t\tBecause of\n\t\t\t\t\t\tthe numerous possible combinations of vehicle models, styles, colors and options, the vehicle pictures on\n\t\t\t\t\t\tthis\n\t\t\t\t\t\tsite may not match your vehicle exactly; however, it will match as closely as possible. Some vehicle images\n\t\t\t\t\t\tshown are stock photos and may not reflect your exact choice of vehicle, color, trim and specification. Not\n\t\t\t\t\t\tresponsible for pricing or typographical errors. Please verify individual inventory price(s) with the\n\t\t\t\t\t\tdealer.\n\t\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n\t\t\t\t\t\tAll information pertaining to any vehicle should be verified through the dealer.\n\t\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n\t\t\t\t\t\t*Notice regarding Security System: All of our vehicles are equipped with an anti-theft device to prevent\n\t\t\t\t\t\ttheft\n\t\t\t\t\t\tfrom our inventory. The advertised price for all of our vehicles does not include the price of the\n\t\t\t\t\t\tanti-theft\n\t\t\t\t\t\tdevice. This device can be purchased for an additional cost, or may be removed at the customer’s option.\n\t\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n\t\t\t\t\t\tAny image of a stock photo may not reflect the model year or color of, and/or equipment offered on, the\n\t\t\t\t\t\tadvertised vehicle. The image is shown for illustration purposes only.\n\t\t\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n\t\t\t\t\t\tProposition 65 Warning: Operating, servicing, and maintaining a passenger vehicle or off-road vehicle can\n\t\t\t\t\t\texpose\n\t\t\t\t\t\tyou to chemicals including engine exhaust, carbon monoxide, phthalates, and lead, which are known to the\n\t\t\t\t\t\tState\n\t\t\t\t\t\tof California to cause cancer and birth defects or other reproductive harm. To minimize exposure, avoid\n\t\t\t\t\t\tbreathing exhaust, do not idle the engine except as necessary, service your vehicle in a well-ventilated\n\t\t\t\t\t\tarea,\n\t\t\t\t\t\tand wear gloves or wash your hands frequently when servicing your vehicle. For more information go to "
                ),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://www.p65warnings.ca.gov/products/passenger-vehicle/",
                    },
                  },
                  [_vm._v("p65Warnings")]
                ),
              ]),
              _vm._v(" "),
              _c("p", [_vm._v("ADM = Added Dealer Markup")]),
            ]),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }